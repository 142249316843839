import {Theme} from 'theme-ui'

export const theme: Theme = {
  fonts: {
    body: '"eurostile", sans-serif',
    heading: '"eurostile", sans-serif',
  },
  fontWeights: {
    body: 500,
    heading: 400,

    regular: 400,
    medium: 500,
    bold: 700,
    heavy: 800,
    black: 900,
  },
  breakpoints: [
    '768px',
  ],
  fontSizes: [
    18,
    26,
    32,
    36,
    40,
    44,
    50,
    64,
  ],
  colors: {
    text: '#58595b',
    background: '#ffffff',
    footer: '#6d6e70',
    graySection: '#e6e7e8',
    atxDarkGray: '#403c3d',
    atxMediumGray: '#58595b',
    atxLightGray: '#b2b1b1',
    atxOrange: '#d15e14',
    atxBlue: '#316094',
    atxYellow: '#ffb71b',
  },
  buttons: {
    menu: {
      cursor: 'pointer',
      '&:hover': {
        color: 'atxOrange'
      },
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontSize: 0,
      a: {
        color: 'white',
        textDecoration: 'none',
        '&:hover': {
          color: 'atxOrange',
          textDecoration: 'underline',
        },
        '&.active': {
          color: 'atxOrange',
        },
      },
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: '1',
      fontSize: [5, 7],
      margin: 0,
    },
    h2: {
      color: 'text',
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: [3, 6],
      margin: 0,
    },
    h3: {
      color: 'text',
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: [1, 4],
      margin: 0,
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: [1, 2],
      margin: 0,
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: 0,
      margin: 0,
    },
    h6: {
      color: 'text',
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: 0,
      margin: 0,
    },
  },
}
